<template>
	<div>
		<Navigation elevate-background elevate-shadow elevate-on-scroll="#scrolling-element" elevate-border />
		<div class="px-6">
			<div class="max-w-7xl mx-auto">
				<slot />
			</div>
		</div>
		<Footer />
	</div>
</template>
